<template>
    <div class="recommended-alternatives">
        <h3>{{$root.language.wsm_oos_alternatives}}</h3>

        <p class="alternative-text" v-html="productRecommendations.alternateProductRecommText"></p>
        <div class="products-container" v-if="productRecommendations.alternateRecommendedProducts != undefined">            
            <div class="individual-products" v-for="product in productRecommendations.alternateRecommendedProducts" :key="product.id" >   
                <h4> <span>{{ product.name }}</span> {{product.description}}</h4>                
                <ProductAlternativeImage :productAlternative="product"></ProductAlternativeImage>   
               
                <a :href="`${basedir}/${product.productPageLink}`" target="_blank">Læs mere</a>  
            </div>
        </div>
    </div>
</template> 

<script>
import ProductAlternativeImage from './ProductAlternativeImage.vue'

export default {
    name: 'RecommendedAlternatives',
    components: {
        ProductAlternativeImage
    },
    props: ['productRecommendations'],
    data() {
        return {
           
        }
    },
    created() {
       
    },
    computed: {
        basedir: function() {
            return window.basedir;
        }
    }
}
</script>

<style scoped lang="scss">
.recommended-alternatives {      
   padding:0 7%;   
   max-height: 600px; // Added max-height
   overflow-y: auto;  // Added vertical scroll
   width:54%;
    min-width: 500px;

   *{
        box-sizing: border-box; 
    } 
 
    h3{
        color: #63afad;
        font-size:1.8em;      
        font-weight: bold;
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        line-height: 1.4em;  
        margin-bottom: 40px;      
    }

    .alternative-text{
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        line-height: 1.4em;
        margin-bottom: 15px;
    }

    .products-container{
        margin-top: 2%;
        display: inline-flex;  
        flex-direction: row;
        flex-wrap: wrap;
        gap: 35px;

        .individual-products{
            width: 45%;

            img{
                width: 100%;
                height: auto;
            }

            a{
                text-decoration: none;
                text-align: center;
                display: block;
                margin-top: 10px;
            }
        }
    }

    p{          
        padding-bottom:0;
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        line-height: 1.4em;
    }

    .individual-products{
        margin-bottom: 15px;

        h4{
            font-weight: normal;
            height: 40px;
            text-align: center;
        
            span{
                font-weight: bold;
                color: #4d4d4d;
            }
        }
    }
} 

@media screen and (max-width: 700px) {
    *{
        box-sizing: border-box; 
    }

    .recommended-alternatives{
        background: aliceblue;
        width: 100%;
        padding: 6%;
        margin: 0;
        max-height: 100%;
        overflow-y: unset;
        min-width: auto;

        text-align: center;

        .products-container {
            .individual-products{
                width:90%;
            }
        }
    }
}
</style> 