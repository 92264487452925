<template>
    <div class="productImage" v-if="productAlternative.productImageUrl"> 
        <a :href="`${basedir}/${productAlternative.productPageLink}`">
            <img 
                :src="imageUrl"
                :alt="productAlternative.name"
                :srcset="imageSet ? imageSet.set : ''"
                class="product-image"
                @error="handleImageError"
            />
        </a>
    </div>
</template>

<script>
import * as Bowser from "bowser";
import { default as fetchImage } from '../../../globalComponents/fetchImageUrl';

const fallBackBrowserVersions = {
    Chrome: 31,
    Edge: 17,
    Safari: 15.6,
    Firefox: 64,
    Opera: 18,      
    AndriodBrowser: 4.1,
    KaiOSBrowser: 2.5
};

export default {
    name: 'ProductAlternativeImage',
    props: {    
        productAlternative: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            imageSize: 181,
            newImageUrl: null,
            imageSrcSets: [1, 1.3, 1.6, 2, 2.5, 2.8],
        }
    },
    computed: {
        imageUrl() {
            return this.newImageUrl ? `${this.newImageUrl}?w=${this.imageSize}` : `${this.productAlternative.productImageUrl}?w=${this.imageSize}`;
        },
        isBrowserCompatableForWebPImages() {
            // Defensive check for Bowser
            if (!Bowser || !Bowser.name || !Bowser.version) {
                console.warn('Bowser detection failed, defaulting to png');
                return "png";
            }
            return Number(Bowser.version) > (fallBackBrowserVersions[Bowser.name] || 0) ? "webp" : "png";
        },
        imageSet() {
            if (!this.newImageUrl) return null;
            
            const mode = 'x'; // x or w
            let srcString = '';
            let sizeAttr = [];
            
            this.imageSrcSets.forEach((size, index) => {
                const isLast = (index + 1) === this.imageSrcSets.length;
                const delimiter = isLast ? '' : ', ';
                
                if (mode === 'x') {
                    srcString += `${this.newImageUrl}?w=${Math.floor(this.imageSize * size)} ${size}x${delimiter}`;
                } else if (mode === 'w') {
                    const calculatedSize = Math.floor(this.imageSize * size);
                    srcString += `${this.newImageUrl}?w=${calculatedSize} ${calculatedSize}w${delimiter}`;
                    sizeAttr.push(calculatedSize);
                }
            });
            
            return {
                set: srcString,
                size: mode === 'w' ? sizeAttr : ''
            };
        },
        basedir() {
            return window.basedir || '';
        }
    },
    mounted() {
        this.initializeImage();
    },
    watch: {
        'productAlternative.productImageUrl': function(newUrl, oldUrl) {
            if (newUrl !== oldUrl) {
                this.initializeImage();
            }
        }
    },
    methods: {
        async initializeImage() {
            if (!this.productAlternative || !this.productAlternative.productImageUrl) {
                console.warn('Product alternative or image URL is missing');
                return;
            }
            
            try {
                this.loading = true;
                this.newImageUrl = await this.getImageUrl(this.productAlternative);
            } catch (error) {
                console.error('Error initializing image:', error);
                this.newImageUrl = this.productAlternative.productImageUrl;
            } finally {
                this.loading = false;
            }
        },
        handleImageError() {
            console.warn('Image loading error, falling back to original URL');
            this.newImageUrl = this.productAlternative.productImageUrl;
        },
        async getImageUrl(product) {
            if (!product || !product.productImageUrl) {
                return product?.productImageUrl || '';
            }
            
            const imageParams = {
                url: product.productImageUrl,
                flap: this.$root?.flaptype || "",
                fileExtension: this.isBrowserCompatableForWebPImages,
                size: "",
            };

            try {
                return await fetchImage(imageParams);
            } catch (error) {
                console.error("Error fetching image:", error);
                return product.productImageUrl;
            }
        }
    }
}
</script>

<style scoped lang="scss">
.productImage {  
    width: 181px;
    min-height: 101px;
    margin: 0 auto;
    
    img, .product-image {
        width: 100%;
        max-width: 181px;
        height: auto;
        display: block;
    }
}
</style> 