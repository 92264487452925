
/*
  --Fetching new image url (/productImageServlet/IDxxx.th3.webp)
  --imageParams is an object contains parameters of image url to fetch.
  *@param {url} imageParams_1 -- original url or ID of image from compiere
  *@param {flap} imageParams_2 -- flap type to view with or without mortar image
  *@param {fileExtension} imageParams_3 -- browser support format (WEBP or PNG)
  *@param {size} imageParams_4 -- size of the image
  *@param {imagesWithoutID} imageParams_5 -- images like evelle,prelox..etc have ID & need to show them with flap 'NM'.
*/
const fetchImageUrl = async (imageParams) => {
  function isValidUrl(url) {
      return ["http://", "https://"].some(protocol => url.startsWith(protocol));
  }
  function fetchImage(imageId, imageParams) {
    return `${basedir}/productImageServlet/${imageId}${imageParams.flap}.${imageParams.size}.${imageParams.fileExtension}`;
  }
  const isValidUrls = isValidUrl(imageParams.url);
  if (isValidUrls) {  
      const reg = /.+?:\/\/.+?(\/.+?)(?:#|\?|$)/;
      const pathname = reg.exec(imageParams.url)[1];
      const pathArray = pathname.split("/");
      const imageLInk = pathArray.length > 1 ? pathArray[2] : pathArray[1];
      const imageIdArray = imageLInk.split(".");
      const imageId = imageIdArray[0];
      if(isNaN(+imageId)){
        return  imageParams.url;
      }else{
        imageParams.size = imageIdArray[1]? imageIdArray[1] : "th3" ;
        return  fetchImage(`ID${imageId}`, imageParams);
      }
  }else if((/((^ID|SKU)\d+|(^ID|SKU)\d+\.th\d.png)$/i).test(imageParams.url)){
    const imageIdArray = imageParams.url.split(".");
    const imageId = imageIdArray[0];
    imageParams.size = imageIdArray[1]? imageIdArray[1] : "th3" ;
    return fetchImage(imageId, imageParams);  
  }else {
    return  imageParams.url;
  }
};

export default fetchImageUrl;