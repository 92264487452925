<template>
    <div class="grid">
        <div class="productContainer">           
            <div class="productImage" ref="imageContainer" v-if="view == 'grid'">
                <!-- Nutriverse -->
                <a :href="readMoreLink" v-if="this.$root.isNutriverse">                  
                     <v-lazy-image  @load="adjustHeight" v-if="this.$root.customimage == 'false' || this.$root.customimage.length == 0 || typeof $root.customimage == 'undefined'" :alt="convertToPlainText(product.name)" :title="convertToPlainText(product.name)" :src-placeholder="product.imageUrl+'?w=10&quality=1'" :srcset="imageSet.set" :src="product.imageUrl+'?w='+imageSize" :rel="preload ? 'preload' : ''" />
                    <v-lazy-image  @load="adjustHeight" v-if="typeof this.$root.customimage != 'undefined' && this.$root.customimage == 'true' && this.$root.customimage.length > 0" :alt="convertToPlainText(product.name)" :title="convertToPlainText(product.name)" :src="this.$root.customimgurl.includes('sg0') ? $root.customimgurl+'?w='+imageSize : $root.customimgurl" :srcset="imageSet.set" :rel="preload ? 'preload' : ''" />
                </a>
                <a :href="readMoreLink" v-if="!this.$root.isNutriverse">
                    <!--  <img :width="isUkFrontpage ? 290 : 350" height="140" @load="adjustHeight" v-if="this.$root.customimage == 'false' || this.$root.customimage.length == 0 || typeof $root.customimage == 'undefined'" :alt="product.name" :title="product.name" :src="product.imageUrl+'?w='+(isUkFrontpage ? 290 : 350)" :rel="preload ? 'preload' : ''" /> -->
                     <!-- <v-lazy-image :src-placeholder="product.imageUrl+'?w=10&quality=1'" :srcset="imageSet.set" :src="editing ? editData.imageUrl : product.imageUrl+'?w='+imageSize" :size="imageSet.size" /> -->
                     <v-lazy-image  @load="adjustHeight" v-if="this.$root.customimage == 'false' || this.$root.customimage.length == 0 || typeof $root.customimage == 'undefined'" :alt="product.name" :title="product.name" :src-placeholder="`${product.imageUrl}?w=10&quality=1`" :srcset="imageSet.set" :src="`${this.fetchImageUrl(product.imageUrl,product.name)}?w=${imageSize}`" :rel="preload ? 'preload' : ''" />
                    <v-lazy-image  @load="adjustHeight" v-if="typeof this.$root.customimage != 'undefined' && this.$root.customimage == 'true' && this.$root.customimage.length > 0" :alt="product.name" :title="product.name" :src-placeholder="`${this.$root.customimgurl}?w=10&quality=1`" :src="this.$root.customimgurl.includes('sg0')? `${this.fetchImageUrl($root.customimgurl,product.name)}?w=${imageSize}` : this.fetchImageUrl($root.customimgurl, product.name)" :srcset="imageSet.set" :rel="preload ? 'preload' : ''" />
                </a>
            </div>
            <div class="productInfo">
                <h3 v-html="product.name"></h3>
                <span v-if="typeof this.$root.customText == 'undefined' || this.$root.customText.length == 0" v-html="productDesc"></span>
                <span v-if="typeof this.$root.customText != 'undefined' && this.$root.customText.length > 0" v-html="this.$root.customText"></span>
                <div class="infoContainer">
                    <a class="readMoreProductLink" :href="$root.pointshop ? basedir+$root.pointshopUrlPrefix+product.productPageLink : basedir+'/'+product.productPageLink + (this.product.isAllowSubscription && $root.subenabled ? `#variant=${this.product.productId}` : '')">{{this.$root.language.wsm_MoreInfo}}</a>
                </div>
            </div>
            <div :class="[(parseInt(product.discount) > 0 || product.taxRateFactor == 1) ? 'extraHeight' : '', 'productActionBar']">
                <a :href="$root.pointshop ? basedir+$root.pointshopUrlPrefix+product.productPageLink : basedir+'/'+product.productPageLink + (this.product.isAllowSubscription && $root.subenabled ? `#variant=${this.product.productId}` : '')"> <span v-html="product.quantityString" class="quantityProduct"></span></a>
                <p class="productPrice" v-if="price != 'NaN' && product.availability != 2">{{getVisString(product.price)}}</p>
                <additionalInfo v-if="(view == 'grid' && (product.discount > 0 || $root.iscompany)) || (view == 'list' && mobile)" :data="product" :companyPriceIncludingVat="getVisString(product.companyPriceIncludingVat)" :listPrice="getVisString(product.listPrice)"
                    :localDiscount="getVisString(product.discount)" :tax="product.taxRateFactor" :showBuyButton="showBuyButtonLogic" />
                <addToBasket v-if="showBuyButtonLogic" @setQuant="setQuant" :class="['addToBasketButton', product.availability == 2 ? 'nosale' : '', $root.admin ? 'adminBtn' : '']" @gtag="gtag" :id="product.productId" :quantity="product.quantity" :availability="product.availability"
                    :backorder="this.$root.backorder" :preorder="this.$root.preorder" :productname="product.name" />
                <a v-if="view == 'grid' || (view == 'grid' && mobile)" class="infoLink" :style="!showBuyButtonLogic ? '	float: right;margin-top: -11px;' : ''" :href="$root.pointshop ? basedir+$root.pointshopUrlPrefix+product.productPageLink : basedir+'/'+product.productPageLink">
                    <div class="infoBtn">
                        <i class="fa fa-info"></i>
                    </div>
                </a>
            </div>
        </div>
<script type="application/ld+json">
    [{
        "@context": "http://schema.org",
        "@type": "Product",
        "description": "{{productDesc}}",
        "name": "{{product.productName}}",
        "image": "{{this.$root.customimage == 'false' || this.$root.customimage == '' || typeof this.$root.customimage == 'undefined' ? product.imageUrl : this.$root.customimgurl}}",
        "sku": "{{product.productID}}",
        "brand": {
            "@type": "Brand",
            "name": "Pharma Nord"
        },
        "mpn": "{{locale+product.productID}}",
        "offers": {
            "@type": "Offer",
            "availability": "{{product.availability == 5 ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock'}}",
            "price": {{product.listPrice}},
            "priceCurrency": "{{product.CurrencyIso}}",
            "url": "{{location.origin + basedir+'/'+product.productPageLink}}"
        }
    }]
</script>
    </div>
</template>

<script>
    import addToBasket from "./components/addToBasket";
    import additionalInfo from "./components/additionalInfo";
    var priceHandler = require('../../globalComponents/visualFormattingForPrices');
    export default {
        name: 'productWidget',
        components: {
            addToBasket,
            additionalInfo,
        },
        data() {
            return {
                product: this.$root.product,
                view: 'grid',
                loading: false,
                imageSrcSets: [1, 1.3, 1.6, 2, 2.5, 2.8, 3]
            }
        },
        provide() {
            // use function syntax so that we can access `this`
            return {
                productAlternative: this.product
            }
        },
        mounted() {
            /*          setTimeout(() => {
            if (this.$root.container) {
                //control height
                var selector = document.querySelectorAll('.' + this.$root.container + ' .productContainer')
                var height = 0;
                selector.forEach((el, index) => {
                    el.querySelector('.productInfo').removeAttribute('style')
                    height = 0;
                    if (selector.length == (index + 1)) {
                        selector.forEach((el2, index1) => {
                            if (el2.offsetHeight > height) {
                                height = el2.offsetHeight;
                            }
                            if (selector.length == (index1 + 1)) {
                                selector.forEach((widget, index2) => {
                                    widget.children[1].style.minHeight = (widget.children[1].offsetHeight + (height - widget.offsetHeight)) + 'px';
                                })
                            }
                        })
                    }
                })
            }
            },100) */
        },
        methods: {
            fetchImageUrl (url, name){
                const imageParams = {
                    url : url,
                    flap : "M",
                    fileExtension : "webp",
                    size : "th3",
                };
                function isValidUrl(url) {
                    return ["http://", "https://"].some(protocol => url.startsWith(protocol));
                }
                function fetchImage(imageId, imageParams) {
                    return `${basedir}/productImageServlet/${imageId}${imageParams.flap}.${imageParams.size}.${imageParams.fileExtension}`;
                }
                const isValidUrls = isValidUrl(imageParams.url);
                if (isValidUrls) {  
                    const reg = /.+?:\/\/.+?(\/.+?)(?:#|\?|$)/;
                    const pathname = reg.exec(imageParams.url)[1];
                    const pathArray = pathname.split("/");
                    const imageLInk = pathArray.length > 1 ? pathArray[2] : pathArray[1];
                    const imageIdArray = imageLInk.split(".");
                    const imageId = imageIdArray[0];
                    if(isNaN(+imageId)){
                        return  imageParams.url;
                    }else{
                        imageParams.size = imageIdArray[1]? imageIdArray[1] : "th3" ;
                        return  fetchImage(`ID${imageId}`, imageParams);
                    }
                }else if((/((^ID|SKU)\d+|(^ID|SKU)\d+\.th\d.png)$/i).test(imageParams.url)){
                    const imageIdArray = imageParams.url.split(".");
                    const imageId = imageIdArray[0];
                    imageParams.size = imageIdArray[1]? imageIdArray[1] : "th3";
                    return fetchImage(imageId, imageParams);  
                }else {
                    return  imageParams.url;
                }
            },
            gtag: function(id) {
                if(JSON.parse(document.cookie.split(';').find(c => c.trim().startsWith('cconsent')).split('=')[1]).categories.statistics.wanted) {
                    gtag('event', 'add_to_cart', {
                        "items": [{
                            'item_name': this.product.name,
                            'item_id': id,
                            'price': this.product.price
                        }],
                        currency: this.product.CurrencyIso,
                        value: this.product.price,
                        'event_category': 'Single',
                    });
                    if(window.hasFbq) {
                        fbq('track', 'AddToCart', {
                            content_ids: [id],
                            content_name: this.product.name,
                            currency: this.product.CurrencyIso,
                            value: this.product.price
                        });
                    }
                }
            },
            setQuant: function(input) {
                this.product.quantity = input
            },
            getNewData: function(prodid) {
                if(prodid) {
                    this.loading = true;
                    axios.get(basedir + '/ajaxRequestServlet?mode=GetProduct&isSpecial=true&payload={productID: ' + prodid + ', isPointShop: ' + this.$root.pointshop + '}').then(response => {
                    this.product = response.data.products[0]
                    this.$root.product = response.data.products[0]
                    this.loading = false;
                });
                } else {
                    this.product.quantity = 0;
                }
            },
            getVisString: function(money) {
                return priceHandler.default.visualFormattingForPrices(money, this.product.CurrencyIso, this.product.currencyPrecision, this.product.locale)
            },
            adjustHeight() {
                if (this.$root.container) {
                    //control height
                    var selector = document.querySelectorAll('.' + this.$root.container + ' .productContainer')
                    var height = 0;
                    selector.forEach((el, index) => {
                        el.querySelector('.productInfo').removeAttribute('style')
                        height = 0;
                        if (selector.length == (index + 1)) {
                            selector.forEach((el2, index1) => {
                                if (el2.offsetHeight > height) {
                                    height = el2.offsetHeight;
                                }
                                if (selector.length == (index1 + 1)) {
                                    selector.forEach((widget, index2) => {
                                        widget.children[1].style.minHeight = (widget.children[1].offsetHeight + (height - widget.offsetHeight)) + 'px';
                                    })
                                }
                            })
                        }
                    })
                }
            },           
            convertToPlainText(html) {
                const parser = new DOMParser();
                const parsedHtml = parser.parseFromString(html, 'text/html');
                let plainText = '';
                const traverseNodes = (node) => {
                    if (node.nodeType === Node.TEXT_NODE) {
                        plainText += `${node.textContent} `;
                    } else if (node.nodeType === Node.ELEMENT_NODE) {
                        const children = node.childNodes;
                        for (let i = 0; i < children.length; i++) {
                            traverseNodes(children[i]);
                        }
                    }
                };
                traverseNodes(parsedHtml.body);      
                return plainText.trim();
            },
        },
        computed: {
            price: function() {
                return Number(this.product.price).toLocaleString(this.locale.replace('_', '-'), {
                    minimumFractionDigits: this.product.currencyPrecision,
                    maximumFractionDigits: this.product.currencyPrecision
                });
            },
            localDiscount: function() {
                return Number(this.product.discount).toLocaleString(this.locale, {
                    minimumFractionDigits: this.product.currencyPrecision,
                    maximumFractionDigits: this.product.currencyPrecision,
                });
            },
            readMoreLink: function() {
                return this.$root.pointshop ? this.basedir + this.$root.pointshopUrlPrefix + this.product.productPageLink : this.basedir + '/' + this.product.productPageLink + (this.product.isAllowSubscription && this.$root.subenabled ? `#variant=${this.product.productId}` : '')
            },
            location: function() {
                return window.location;
            },
            locale: function() {
                return window.locale
            },
            showAdditionalInfo: function() {
                if (parseInt(this.product.discount) > 0 || this.product.taxRateFactor == 1) {
                    return true;
                } else {
                    return false;
                }
            },
            productDesc: function() {
                return typeof this.product.description != 'undefined' ? this.product.description.replace(/\s\s+/g, ' ').replace(/(\r\n|\n|\r)/gm, '').replace(/(<([^>]+)>)/ig, '').trim() : ''
            },
            basedir: function() {
                return window.basedir;
            },
            showBuyButtonLogic: function() {
                if (this.$root.display.showPriceNoBuyButton) {
                    return false;
                } else {
                    if (this.onlyBuyWhenLoggedInCheck) {
                        return true
                    } else {
                        return false;
                    }
                }
            },
            imageSize: function() {
                if(!this.isUkFrontpage && this.$root.frontpage)
                    return 327;
                if(this.isUkFrontpage && this.$root.frontpage)
                    return 180;
                if(!this.$root.frontpage)
                    return 296;
            },
            imageSet: function() {
                var mode = 'x' //w, x
                var returnObj = {}
                var sizeAttr = [];
                var srcString = '';
                this.imageSrcSets.forEach((size, index) => {
                    switch (mode) {
                        case 'x':
                            srcString += this.fetchImageUrl(this.product.imageUrl,this.product.name) + '?w=' + Math.floor(this.imageSize * size) + ' ' + size + 'x' + ((index + 1) == this.imageSrcSets.length ? '' : ', ')
                            return {
                                set: srcString,
                                size: ''
                            };
                            break;
                        case 'w':
                            srcString += this.fetchImageUrl(this.product.imageUrl,this.product.name) + '?w=' + Math.floor(this.imageSize * size) + ' ' + Math.floor(this.imageSize * size) + 'w' + ((index + 1) == this.imageSrcSets.length ? '' : ', ')
                            sizeAttr.push(Math.floor(this.imageSize * size))
                            break;
                    }
                })
                returnObj["set"] = srcString;
                if(mode == 'w') {
                    returnObj["size"] = sizeAttr;
                } else {
                    returnObj["size"] = '';
                }
                return returnObj
            },
            isUkFrontpage: function() {
                return document.getElementsByClassName('uk-style').length > 0 && window.isFrontpage
            },
            onlyBuyWhenLoggedInCheck: function() {
                if (this.$root.display.OnlyBuyWhenLoggedIn) {
                    if (this.$root.display.loggedIn) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true
                }
            },
            preload: function() {
                return document.getElementsByClassName("frontpage2015").length > 0
            }
        },
    };
</script>

<style lang="scss" scoped>
    .productContainer {
        margin-bottom: 40px;
    }
    .infoBtn {
        float: right;
        padding: 1px;
        width: 6%;
        min-width: 27px;
        text-align: center;
        background: #8d8877;
        margin-top: 7px;
        margin-left: 9px;
        i {
            color: #f5f2e8;
        }
    }
    .grid {
        max-width: 345px;
        display: inline-block;
        vertical-align: top;
        margin-left: 5px;
        margin-right: 5px;
        .productContainer {
            border: 2px solid #f5f2e8;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }
        h3 {
            text-align: left;
            margin-bottom: 8px;
            color: #796e5e;
            margin-top: 11px;
            padding: 14px;
            padding-bottom: 0;
        }
        .productImage {
            display: block;
            width: auto;
            height: auto;
            padding: 7px;
            img {
                width: auto;
                max-width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
        .productInfo {
            width: 100%;
            display: block;
            background: rgba(245, 242, 232, 0.41);
            text-align: left;
            &>span {
                width: 87%;
                display: block;
                text-align: left;
                padding: 7%;
                padding-left: 14px;
                padding-top: 0;
                font-size: 0.85em;
            }
            .readMoreProductLink {
                font-weight: 700;
                display: block;
                margin-top: 18px;
                font-size: 15px;
                margin: 0;
                padding: 14px;
                padding-left: 14px;
                padding-top: 0;
            }
            .infoContainer {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                .subAvailable {
                    font-size: 0.8rem;
                    background: #e8b75e;
                    margin-left:7%;
                    margin-bottom:1rem;
                    color: white;
                    padding: 0 0.5rem;
                    border-radius: 6px;
                    text-transform: uppercase;
                }
            }
        }
        .addToBasketButton {
            width: 88%;
            float: none;
            margin-right: 0px;
            max-width: 300px;
            display: inline-block;
            margin-top: 7px;
        }
        .infoLink {
            display: inline-block;
            width: 12%;
            margin-bottom: 0;
            vertical-align: bottom;
            .infoBtn {
                text-align: center;
                margin-left: 0;
                width: 91%;
                height: 27px;
                max-width: 30px;
                i {
                    vertical-align: bottom;
                }
            }
        }
    }
    .productActionBar {
        padding: 10px;
        background: #f5f2e8;
        text-align: left;
    }
    .productPrice {
        display: inline-block;
        margin-left: 8px;
        font-weight: bold;
        color: #004570;
        span {
            font-weight: bold;
            color: #004570;
        }
    }
    hr {
        width: 87%;
    }
    .productShopMoble {
        .list {
            .productInfo {
                width: 100%;
                margin-bottom: 10px;
            }
            .productActionBar {
                width: 94%;
            }
            .productImage {
                display: block;
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 900px) {
        .grid {
            .addToBasketButton {
                width: 80%;
            }
        }
    }
    @media screen and (max-width: 700px) {
        .grid {
            .productContainer {
                position: relative;
            }
            .productImage img {
                width: 100%;
            }
            .addToBasketButton {
                width: calc(97% - 30px);
                float: none;
                max-width: unset;
            }
            .infoLink {
                display: inline;
            }
            .infoBtn {
                height: 27px;
                min-width: 27px;
                i {
                    vertical-align: bottom;
                }
            }
        }
        .list {
            .productActionBar {
                .addToBasketButton {
                    width: calc(100% - 38px);
                    float: none;
                    max-width: unset;
                }
                .adminBtn {
                    width: calc(100% - 62px);
                    max-width: unset;
                    float: left;
                }
                .infoBtn {
                    padding-top: 5px;
                    padding-bottom: 6px;
                    width: 27px;
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        .grid {
            margin: 0;
        }
    }
</style>

<style lang="scss">
    .productContainer span p,
    .productActionBar p {
        margin: 0;
    }
    .grid .productInfo>span>p {
        color: #796e5e;
        font-size: 13px;
    }
    .quantityProduct {
        display: inline-block;
        font-weight: bold;
        text-align: left;
        min-width: 58px;
    }
    .list .quantityButtons {
        width: 91px;
    }
    .col1 .recommendedProducts {
        background: white;
        .grid {
            max-width: unset;
            width: 47%;
        }
    }
    @media screen and (max-width: 825px) {
        .col1 .recommendedProducts {
            .grid {
                width: 100%;
                max-width: unset !important;
                margin: 0;
            }
        }
    }
    .frontpageproducts {
        gap: 15px !important;
        margin-bottom: 20px;

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        .right,.left{
            width:100%;
            height:100%;
        }
        .grid {
            height: 100%;
            min-width: 198px;
            .productContainer {
                display: flex;
                flex-direction: column;
                height: 100%;
                margin-bottom: 0;
                .productInfo{
                    flex-grow: 1;
                }
                .addToBasketButton {
                    width: 82% !important;
                }
                .infoLink {
                    float: right;
                }
            }
        }
    }
    @media screen and (max-width: 900px) {
        .frontpageproducts {
            margin: 15px 15px;
        }
    }
    @media screen and (max-width: 650px) {
        .frontpageproducts {
            // flex: 0 0 50%;
            .grid {
                max-width: none;
            }
            .left,
            .right {
                .grid {
                    &:last-child {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 450px) {
        .frontpageproducts {
            .grid {
                // flex-basis: 80%;
                // &:last-child {
                //     width: 100%;
                //     max-width: 100%;
                // }
            }
        }
    }
</style>
</style>
