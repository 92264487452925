import { render, staticRenderFns } from "./RecommendedAlternatives.vue?vue&type=template&id=3822cc3e&scoped=true"
import script from "./RecommendedAlternatives.vue?vue&type=script&lang=js"
export * from "./RecommendedAlternatives.vue?vue&type=script&lang=js"
import style0 from "./RecommendedAlternatives.vue?vue&type=style&index=0&id=3822cc3e&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3822cc3e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/dvl/web2/compiere-all/pharmanord2011/template/webapps/productwidget/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3822cc3e')) {
      api.createRecord('3822cc3e', component.options)
    } else {
      api.reload('3822cc3e', component.options)
    }
    module.hot.accept("./RecommendedAlternatives.vue?vue&type=template&id=3822cc3e&scoped=true", function () {
      api.rerender('3822cc3e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/RecommendedAlternatives.vue"
export default component.exports