var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "recommended-alternatives" }, [
    _c("h3", [_vm._v(_vm._s(_vm.$root.language.wsm_oos_alternatives))]),
    _c("p", {
      staticClass: "alternative-text",
      domProps: {
        innerHTML: _vm._s(
          _vm.productRecommendations.alternateProductRecommText
        ),
      },
    }),
    _vm.productRecommendations.alternateRecommendedProducts != undefined
      ? _c(
          "div",
          { staticClass: "products-container" },
          _vm._l(
            _vm.productRecommendations.alternateRecommendedProducts,
            function (product) {
              return _c(
                "div",
                { key: product.id, staticClass: "individual-products" },
                [
                  _c("h4", [
                    _c("span", [_vm._v(_vm._s(product.name))]),
                    _vm._v(" " + _vm._s(product.description)),
                  ]),
                  _c("ProductAlternativeImage", {
                    attrs: { productAlternative: product },
                  }),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.basedir + "/" + product.productPageLink,
                        target: "_blank",
                      },
                    },
                    [_vm._v("Læs mere")]
                  ),
                ],
                1
              )
            }
          ),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }