<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" @click="$emit('close')">
                <div class="modal-container" @click.stop="">                 
                    <div ref="modalBody" class="modal-body">
                        <slot name="body">
                            <transition-group :name="animation" class="transition-group">
                                <div v-if="backorder && showStep1" class="step1" key="1">
                                    <p class="choose">{{$root.language.wsm_outOfStockOptions}}</p>
                                    <div class="backorderContent stepContent" @click="backOrderAdd">
                                        <p class="step">1</p>
                                        <div class="stepDescContainer">
                                            <p class="stepDesc">{{$root.language.wsm_AddToBasketModal}}</p>
                                            <p class="contentDesc">{{$root.language.wsm_backOrderDesc}}</p>
                                        </div>
                                    </div>
                                    <div class="getNotifiedContent stepContent" @click="stepForward('notify', 2)">
                                        <p class="step">{{backorder ? '2' : '1'}}</p>
                                        <div class="stepDescContainer">
                                            <p class="stepDesc">{{$root.language.wsm_Preorder}}</p>
                                            <p class="contentDesc">{{$root.language.wsm_preorderDesc}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="step2" v-if="showStep2" :key="2">
                                    <div class="step2Notify" v-if="choice == 'notify'">
                                        <h3><i class="fas fa-bell"></i> {{$root.language.wsm_Preorder}}</h3>
                                        <p>{{$root.language.wsm_preorderDesc}}</p>
                                    </div>
                                    <label>{{$root.language.wsm_YourEmail}}</label>
                                    <input type="text" v-model="mail" :placeholder="$root.language.wsm_YourEmail" required />
                                    <br />                                   
                                    <div class="newsletter">
                                        <input v-model="newsletterSignUp" id="newsletterChck" type="checkbox" />
                                        <label for="newsletterChck">{{$root.language.wsm_NewsletterOK}} - {{$root.language.wsm_checkOutNewsletterInput}}</label>
                                    </div>
                                    <button :disabled="mail == '' || !validEmail" @click="stepForward(choice, 3)">
                                        <i class="fas fa-bell"></i> {{$root.language.wsm_Preorder}}
                                    </button>
                                </div>
                                <div class="step3" v-if="showStep3" :key="3">
                                    <div class="step3Complete">
                                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                        </svg>
                                        <transition name="header-fade">
                                            <h3 v-if="completed">{{$root.language.wsm_preorderRequestReceived}}</h3>
                                        </transition>
                                        <transition name="header-fade">
                                            <button v-if="showCompleteBtn" @click="$emit('close')">{{$root.language.wsm_ContinueShopping_Checkout}}</button>
                                        </transition>
                                    </div>
                                </div>
                                
                                <button class="modal-default-button backBtn" v-if="(currentStep > 1 && currentStep != 3) && (backorder && !preorder)" @click="stepBack(choice, currentStep-1)" :key="4">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                                    </svg>
                                    {{$root.language.wsm_Back}}
                                </button>
                                <button :key="5" v-if="currentStep != 3" class="modal-default-button" @click="$emit('close')">{{$root.language.wsm_Cancel}}</button>
                            </transition-group>
                            
                            <RecommendedAlternatives v-if="productRecommendations.alternateProductRecommText && !isEmptyObject(productRecommendations)" :productRecommendations="productRecommendations"/>
                        </slot>
                    </div>
                    <div class="modal-footer" v-if="isMobile && productRecommendations.alternateProductRecommText">
                        <slot name="footer">
                            <transition name="btn-fade">
                                <button class="modal-default-button backBtn" v-if="(currentStep > 1 && currentStep != 3) && (backorder && !preorder)" @click="stepBack(choice, currentStep-1)">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                                    </svg>
                                    {{$root.language.wsm_Back}}
                                </button>
                            </transition>
                            <button v-if="currentStep != 3" class="modal-default-button" @click="$emit('close')">{{$root.language.wsm_Cancel}}</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import ProductAlternativeImage from '../../../buybuttoncompact/src/components/ProductAlternativeImage.vue';
import RecommendedAlternatives from './RecommendedAlternatives.vue'
    
    export default {
        components: {
            RecommendedAlternatives
        },
        props: ["backorder", "preorder", "productid", "productname"],
        inject: ['productAlternative'],
        data() {
            return {
                showStep1: true,
                showStep2: false,
                showStep3: false,
                completed: false,
                showCompleteBtn: false,
                currentStep: 1,
                choice: "",
                animation: "slide-fade",
                mail: "",               
                newsletterSignUp: false,
                productRecommendations: {}
            };
        },
        created() {         
            this.productRecommendations = this.productAlternative.productRecommendations ? this.productAlternative.productRecommendations : {};
        
        },
        mounted() {
            window.addEventListener('resize', () => this.$forceUpdate());
            // Prevent body scrolling when modal is open
            document.body.style.overflow = 'hidden';
            
            if (!this.backorder && this.preorder) {
                this.showStep1 = false;
                this.showStep2 = true;
                this.currentStep = 2;
                this.choice = 'notify';
            }
        },
        beforeDestroy() {
            // Re-enable body scrolling when modal is closed
            document.body.style.overflow = 'auto';
        },
        methods: {
            isEmptyObject(obj) {
                return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
            },
            stepForward: function(typePicked, step) {
                this.animation = "slide-fade";
                switch (step) {
                    //add to basket (backorder) complete
                    case 2:
                        this.showStep1 = false;
                        setTimeout(() => {
                            this.showStep2 = true;
                        }, 310);
                        break;
                    case 3:
                        //preorder complete
                        axios.get(
                            window.basedir +`/ajaxRequestServlet?mode=OnStockNotificationAPI&task=subscribeOnStockNotification&newsletterSignUp=${this.newsletterSignUp}&productID=${this.productid}&email=${this.mail}`,
                            
                            {
                                headers: {
                                    'Content-type': 'application/json',
                                    'Cache-Control': 'no-cache',
                                    'Pragma': 'no-cache',
                                    'Expires': '0',
                                }
                            
                        }).then(response => {
                            this.showStep2 = false;
                            document.getElementsByClassName("modal-body")[0].removeAttribute("style")
                            setTimeout(() => {
                                this.showStep3 = true;
                                setTimeout(() => {
                                    this.completed = true;
                                    setTimeout(() => {
                                        this.showCompleteBtn = true;
                                    }, 400)
                                }, 200);
                            }, 350);
                        })
                }
                this.choice = typePicked;
                this.currentStep = step;
            },
            stepBack: function(typePicked, step) {
                this.animation = "slide-fade2";
                switch (step) {
                    case 3:
                        this.showStep3 = false;
                        setTimeout(() => {
                            this.showStep2 = true;
                        }, 350);
                        break;
                    case 2:
                        this.showStep3 = false;
                        setTimeout(() => {
                            this.showStep2 = true;
                        }, 350);
                        break;
                    case 1:
                        this.showStep2 = false;
                        setTimeout(() => {
                            this.showStep1 = true;
                        }, 315);
                }
                this.choice = typePicked;
                this.currentStep = step;
            },
            backOrderAdd: function() {
                this.$emit('addToBasket');
                this.$emit('close');
            }
        },
        computed: {
            validEmail: function() {
                return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.mail);
            },
            isMobile: function() {
                return window.innerWidth <= 700;
            }
        },
    };
</script>

<style lang="scss" scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .modal-container {     
        width: fit-content;
        min-width: auto;
        margin: 0px auto;
        position: relative;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;

        *{
            box-sizing: border-box;
        }
    }
       
    
    .modal-footer {
        display: block;
        position: relative;
        width: 93%;
        height: 30px;
        padding: 23px;
        padding-top: 0;
        .backBtn {
            float: left;
            i {
                color: white;
                vertical-align: text-bottom;
            }
        }
    }
    .modal-body {      
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        padding: 0;

        > * {  // Target direct children
            height: 100%; // Make children fill parent height
            display: flex;
            flex-direction: column;        
        }

        span{
            width: 492px;
            background-color: white;    
            padding: 95px 1px;
            min-height:622px;
        }
        
        &:last-child {
            background-color: #76d7ce1a;
            padding: 0; // Remove default padding
            margin: 0; // Remove margin to extend full height
        }      

        .modal-default-button{
            display: block;
            margin-top: 2%;
            margin: 2% auto 0;
            width: 280px;
        }

        h3 {
            color: #004570;
            font-size: 1.5em;
            text-align: center;
            i {
                font-size: 1em;
                color: inherit;
            }
        }
        .step {
            background: white;
            border: 2px solid #7caf63;
            border-radius: 60px;
            display: inline-block;
            min-width: 44px;
            text-align: center;
            color: #7caf63;
            font-size: 2em;
            font-weight: bold;
            transition: all 0.2s ease-in-out;
            margin-left: -44px;
            margin-top: -6px;
        }
        .step1 {
            margin-top: 34px;
        }
        .choose {
            font-size: 1.2em;
            margin-left: 23px;
        }
        .stepDesc {
            display: inline-block;
            font-size: 1.4em;
            margin-left: 5px;
            font-weight: bold;
            color: #7caf63;
            text-transform: uppercase;
        }
        .contentDesc {
            font-size: 1.2em;
            color: #606060;
            margin-left: 8px;
        }
        .stepContent {
            margin-top: 17px;
            margin-bottom: 28px;
            padding: 20px;
            border: 2px solid white;
            transition: all 0.2s ease-in-out;
            margin-left: 15px;
            &:hover {
                cursor: pointer;
            }
        }
        .step3 {
            h3 {
                font-weight: bold;
                width: 80%;
                margin: 0 auto;
                margin-top: 20px;
                font-size: 1.5em;
                color: #7ac142;
            }
            button {
                background: #004570;
                border: none;
                color: white;
                padding: 10px;
                font-size: 1.2em;
                border-radius: 4px;
                display: block;
                margin: 0 auto;
                margin-top: 36px;
                cursor: pointer;
            }
        }
        .stepDescContainer {
            margin-left: 14px;
            margin-top: 1px;
            display: inline-block;
            width: 94%;
            vertical-align: top;
        }
        .getNotifiedContent {
            border: 2px solid #63afad;
            h3 {
                color: #63afad;
            }
            .step {
                border-color: #63afad;
                color: #63afad;
            }
            .stepDesc {
                color: #63afad;
            }
            &:hover {
                background: #cff3f2;
                .step {
                    background: #63afad;
                    color: white;
                }
            }
        }
        .backorderContent {
            border: 2px solid #7caf63;
            &:hover {
                background: #d9f2cd;
                .step {
                    background: #7caf63;
                    color: white;
                }
            }
        }
        .step2 {
            margin: 0 auto;
            display: block;
            padding: 20%;
            padding-top: 0;
            padding-bottom: 9px;
            &>div {
                margin-top: 10px;
                margin-bottom: 31px;
                p {
                    text-align: center;
                }
                h3 {
                    font-weight: bold;
                    font-size: 2em;
                }
            }
            button {
                font-family: Helvetica, Arial, sans-serif; 
                background: #7caf63;
                border: none;
                color: white;
                padding: 10px;
                font-size: 1.2em;
                border-radius: 4px;
                display: block;
                margin: 0 auto;
                margin-top: 36px;
                cursor: pointer;
                &:disabled {
                    background: #6e6e6e;
                    opacity: 0.6;
                    &:hover {
                        background: #6e6e6e;
                    }
                }
                &:hover {
                    background: #97c580;
                }
            }
            .newsletter {
                text-align: left;
                width: 92%;
                margin: 0 auto;
                margin-top: 31px;
                input {
                    display: inline-block;
                    vertical-align: top;
                    padding-right: 20px;
                    margin-right: 8px;
                }
                label {
                    display: inline-block;
                    text-align: left;
                    width: 90%;
                    cursor: pointer;
                }
            }
            input[type="text"] {
                border-radius: 4px;
                font-size: 1.2em;
                transition: all 0.2s ease-in-out;
                width: 85%;
                text-align: center;
                padding: 10px;
                max-width: unset;
                color: #004570;
                margin: 0 auto;
                &:hover {
                    border: 1px solid #004570;
                }
                &:focus {
                    border: 1px solid #004570;
                }
            }
            label {
                display: none;
            }
            .step2Backorder {
                h3 {
                    color: #7caf63;
                }
                p {
                    color: #878f83;
                }
            }
            .step2Notify {
                h3 {
                    color: #63afad;
                }
                p {
                    color: #9db0b0;
                }
            }
        }
    }
    .modal-default-button { 
        font-family: Helvetica, Arial, sans-serif; 
        background: none;
        color: black;
        font-weight: bold;
        padding: 9px;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        &:hover {
            background: none;
            text-decoration: underline;
        }
    }
    
    .modal-enter {
        opacity: 0;
    }
    .modal-leave-active {
        opacity: 0;
    }
    .modal-enter .modal-container,
    .modal-leave-active .modal-container,
    .modal-leave-active {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    
    .slide-fade-enter-active,
    .slide-fade-leave-active,
    .slide-fade2-enter-active,
    .slide-fade2-leave-active,
    .btn-fade-enter-active,
    .btn-fade-leave-active {
        transition: all 0.3s ease;
    }
    .header-fade-enter-active,
    .header-fade-leave-active {
        transition: all 1s ease;
    }
    .slide-fade-leave-to {
        transform: translateX(-10px);
        opacity: 0;
    }
    .slide-fade-enter {
        transform: translateX(10px);
        opacity: 0;
    }
    .slide-fade2-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
    .slide-fade2-enter {
        transform: translateX(-10px);
        opacity: 0;
    }
    .btn-fade-leave-to {
        transform: translateY(10px);
        opacity: 0;
    }
    .btn-fade-enter {
        transform: translateY(10px);
        opacity: 0;
    }
    .header-fade-leave-to {
        transform: translateY(10px);
        opacity: 0;
    }
    .header-fade-enter {
        transform: translateY(10px);
        opacity: 0;
    }

    $green_backorder: #7ac142;
    $white: #fff;
    $curve: cubic-bezier(0.65, 0, 0.45, 1);
    
    .step3Complete {
        .checkmark {
            width: 56px;
            height: 56px;
            margin: 0 auto;
            margin-top: 50px;
            border-radius: 50%;
            display: block;
            stroke-width: 2;
            stroke: $white;
            stroke-miterlimit: 10;
            box-shadow: inset 0px 0px 0px $green_backorder;
            animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
        }
        .checkmark__circle {
            stroke-dasharray: 166;
            stroke-dashoffset: 166;
            stroke-width: 2;
            stroke-miterlimit: 10;
            stroke: $green_backorder;
            fill: none;
            animation: stroke 0.6s $curve forwards;
        }
        .checkmark__check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            animation: stroke 0.3s $curve 0.8s forwards;
        }
    }
    
    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }
    @keyframes scale {
        0%,
        100% {
            transform: none;
        }
        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }
    @keyframes fill {
        100% {
            box-shadow: inset 0px 0px 0px 30px $green_backorder;
        }
    }


    @media screen and (max-width: 1366px) {
        .modal-container {
            max-width: 90%;   
            max-height: 85vh;           
            overflow-y: auto;

            .modal-body {   
                .recommended-alternatives {
                    padding: 5% 7%;
                    height: auto;
                    text-align: center;
                }
            }  
        }
    }

    @media screen and (max-width: 1024px) {
        .modal-container {
            .modal-body {
                flex-direction: column;

                span {
                    width: 100%;
                    min-height: auto;
                    padding: 50px 0;
                }

                .recommended-alternatives {
                    overflow-y: unset;
                    max-height: 100%;
                    padding: 5% 7%;
                    width: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        .modal-container {
            overflow-y: auto;
            min-height: 86vh;
            
            .modal-body {
                flex-direction: column;

                span {
                    width: 100%;
                    min-height: auto;
                    padding: 50px 0;
                }

                .recommended-alternatives {
                    width: 100%;
                    padding: 5% 7%;
                    height: auto;
                    max-height: 100%;
                    overflow-y: unset;
                }
            }
        }
    }
    
    @media screen and (max-width: 700px) {
        .modal-container {  
            flex-direction: column;
            max-width: 90%;
            min-width: 90%;
            min-height: 447px;
            max-height: 90vh;
            overflow-y: auto;
            .stepDesc {
                font-size: 1em;
            }
            .contentDesc {
                font-size: 0.8em;
            }
            .stepDescContainer {
                width: 86%;
            }
        }
        .modal-body {   
            flex-direction: column;               
            
            .stepContent {
                padding-top: 7px;
                margin-left: 6px;
                padding-bottom: 10px;
            }
            .step {
                font-size: 1.4em;
                min-width: 29px;
                margin-left: -37px;
                margin-top: 6px;
            }
            .choose {
                margin: 0;
                text-align: center;
            }
            .step1 {
                margin-top: 15px;
                padding: 0;
            }
            .step2 {
                margin-top: 15px;
                padding: 15px;
                button {
                    font-family: Helvetica, Arial, sans-serif; 
                    min-width: 91%;
                    margin-bottom: 20px;
                }
                .newsletter {
                    label {
                        width: 86%;
                        font-size: 0.8em;
                    }
                }
                .step2Notify {
                    font-size: 0.8em;
                }
            }

            .modal-default-button{
                width: 91%;
            }

            .step3 {
                h3 {
                    width: 85%;
                }
            }
            
            span {
                width: 100%;
                min-height: auto;
                padding: 20px 0;
            }
            
            > * {
                height: auto;
            }
        }
        .modal-footer {
            box-sizing: border-box;
            width: 100%;
            position: relative;
            height: 66px;
            padding: 14px;
           
            .modal-default-button{
                width: 91%;
                display: block;
                margin: 1% auto 6%;
            }
        }
        
        .recommended-alternatives {
            width: 100% !important;
            min-width: auto !important;
            padding: 15px !important;
            
            .products-container {
                gap: 15px;
                
                .individual-products {
                    width: 100%;
                }
            }
        }
    }
</style>
