import { render, staticRenderFns } from "./additionalInfo.vue?vue&type=template&id=caf515f6&scoped=true"
import script from "./additionalInfo.vue?vue&type=script&lang=js"
export * from "./additionalInfo.vue?vue&type=script&lang=js"
import style0 from "./additionalInfo.vue?vue&type=style&index=0&id=caf515f6&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "caf515f6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/dvl/web2/compiere-all/pharmanord2011/template/webapps/productwidget/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('caf515f6')) {
      api.createRecord('caf515f6', component.options)
    } else {
      api.reload('caf515f6', component.options)
    }
    module.hot.accept("./additionalInfo.vue?vue&type=template&id=caf515f6&scoped=true", function () {
      api.rerender('caf515f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/additionalInfo.vue"
export default component.exports