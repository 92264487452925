var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.productAlternative.productImageUrl
    ? _c("div", { staticClass: "productImage" }, [
        _c(
          "a",
          {
            attrs: {
              href: _vm.basedir + "/" + _vm.productAlternative.productPageLink,
            },
          },
          [
            _c("img", {
              staticClass: "product-image",
              attrs: {
                src: _vm.imageUrl,
                alt: _vm.productAlternative.name,
                srcset: _vm.imageSet ? _vm.imageSet.set : "",
              },
              on: { error: _vm.handleImageError },
            }),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }